<template>
<div id="fever">

  <div class="hidden-sm-and-down">
    <PageHeader/>
  </div>
  <div class="hidden-md-and-up">
    <m-top/>
  </div>

  <div class="hidden-xs-only">
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <div style="width: 100%; max-width: 1294px; margin:0 auto">
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">発熱外来</div>
          </div>
            <div class="separator" style="color: #e5d05e;">

              <div class="content1">
                <div class="side-text">
                  <div class="text1">当院は東京都より指定を受けたクリニックとして、「発熱外来」を行っております。</div>
                  <div class="text1">以下の方は当院でご相談ください。</div>
                  <div class="text1">・発熱や風邪症状でお困りの方</div>
                  <div class="text1">・濃厚接触者の方の方</div>
                  <div class="text1">・感染不安を解消しておきたい方</div>
                </div>
                <div class="seperate_line"></div>
              </div>
              <div class="content1">
                <div class="side-text">
                  <div class="title1">新型コロナウイルス検査について</div>
                  <div class="text1">最も標準的で信頼性の高い検体とされる「鼻腔または鼻咽頭ぬぐい液」でのPCR検査を行っています。多くの国で渡航時の陰性証明として認められている検査方法です。
                    最短で当日中に結果をお知らせします。</div>
                </div>
              </div>
              
              <div class="content1">
                <div class="side-text">
                  <div class="title1">新型コロナウイルス検査について</div>
                </div>
              </div>

              <div class="content1">
                <div class="side-text">
                  <div class="title1">・当院ではPCR法・NEAR法による検査を行っております。</div>
                </div>
              </div>

              <div class="content1">
                <div class="side-box">
                  
                  <div class="side-text" style="width: 70%;">
                    <div class="side-box">
                      <div class="side-line-highlight"></div>
                      <div class="title1">PCR法検査</div>
                    </div>
                    <div class="content_text">
                      <div class="text1" style="padding-left: 15px;">PCRは「Polymerase Chain Reaction（ポリメラーゼ連鎖反応）」の略です。この反応を用いたPCR検査はウイルスの遺伝子の特定の部分を検出する検査です。</div>

                      <div class="text1"style="padding-left: 15px;">採取した唾液にて検査いたします。新型コロナウイルスの遺伝子が含まれているかを調べ、現在の感染状況が分かります。</div>
                    </div>
                  </div>
                  <div class="side-img" style="width:30%">
                    <img src="../../assets/departments/fever/gairai_01.png" class="fit_img">
                  </div>
                </div>
              </div>

              <div class="side-box">
                <div class="side-text" style="width: 70%;">
                  <div class="side-box">
                    <div class="side-line-highlight"></div>
                    <div class="title1">NEAR法検査</div>
                  </div>
                  <div class="content_text">
                    <div class="text1" style="padding-left: 15px;">等温核酸増幅法（NEAR法）によるPCR検査法で、検体採取後15分で結果が判明いたします。 温度を上げ下げし拡散増幅する標準的検査法であるPCR法検査よりも短時間で判定でき、精度も同等です。</div>

                    <div class="text1" style="padding-left: 15px;">ウィルス量が少なくても検出することができます。鼻を綿棒で拭い採取した液（鼻腔拭い液）から検査します。</div>
                  </div>
                </div>
                <div class="side-img" style="width:30%">
                    <img src="../../assets/departments/fever/gairai_02.png" class="fit_img">
                  </div>
              </div>





              <div class="content1">
                <div class="side-text">
                  <div class="title1">・費用</div>
                </div>
              </div>
              <div class="side-box">
                <div style="width: 30px;"></div>
                <div class="side-text" style="width: 300px;">
                  <div class="content1">
                    <div class="text1">PCR等検査（NEAR法）</div>
                    <div class="text1">抗原定性検査</div>
                    <div class="text1">証明書発行</div>
                  </div>
                </div>
                <div class="side-text" style="width: 200px;text-align: right;">
                  <div class="content1">
                    <div class="text1">９,０００円（税込）</div>
                    <div class="text1">３,５００円（税込）</div>
                    <div class="text1">＋１,０００円（税込）</div>
                  </div>
                </div>
              </div>

              <div class="content1">
                <div class="seperate_line"></div>
              </div>

              <div class="content1">
                <div class="side-text">
                  <div class="title1">ご予約について</div>
                  <div class="side-box">
                    <div class="side-text" style="font-size: 15px;">
                      <div class="text1">発熱外来もＰＣＲ検査も完全予約制となります。</div>
                      <div class="text1">ご予約はお電話にて受け付けております。</div>
                    </div>

                    <div class="side-image" style="margin-top: 20px;margin-left: 150px;">
                      <img src="../../assets/icon/icon_tel_hover_28x28@2x.png" style="height:25px;width:25px">
                    </div>
                    <div class="text1" style="margin-top: 20px;">
                      03-6260-6008
                    </div>
                  </div>
                  <div class="table">
                    <table>
                      <tr>
                        <td>診療時間</td>
                        <td>月</td>
                        <td>火</td>
                        <td>水</td>
                        <td>木</td>
                        <td>金</td>
                        <td>土</td>
                        <td>日</td>
                      </tr>
                      <tr>
                        <td>10:00 ～ 13:30</td>
                        <td>○</td>
                        <td>○</td>
                        <td>休</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>休</td>
                      </tr>
                      <tr>
                        <td>14:30 ～ 19:00</td>
                        <td>○</td>
                        <td>○</td>
                        <td>休</td>
                        <td>○</td>
                        <td>○</td>
                        <td>○</td>
                        <td>休</td>
                      </tr>
                    </table>
                  </div>

                  <div class="side-box">
                    <div class="text1">※ 受付は３０分前までとなります。</div>
                    <div class="text1" style="margin-left: 400px;">休診日：水曜・日曜・祝日・年末年始</div>
                  </div>
                </div>
              </div>



          </div>
        </div>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-sm-and-up">
    <div style="width: 100%; max-width: 1294px; margin:0 auto">
        <div class="side-box" style="margin-bottom: 30px;">
          <div class="title">発熱外来</div>
        </div>
        <div class="separator" style="color: #e5d05e;">

          <div class="content1">
            <div class="m_side-text">
              <div class="text1">当院は東京都より指定を受けたクリニックとして、「発熱外来」を行っております。</div>
              <div class="text1">以下の方は当院でご相談ください。</div>
              <div class="text1">・発熱や風邪症状でお困りの方</div>
              <div class="text1">・濃厚接触者の方の方</div>
              <div class="text1">・感染不安を解消しておきたい方</div>
            </div>
            <div class="seperate_line"></div>
          </div>
          <div class="content1">
            <div class="m_side-text">
              <div class="title1">新型コロナウイルス検査について</div>
              <div class="text1">最も標準的で信頼性の高い検体とされる「鼻腔または鼻咽頭ぬぐい液」でのPCR検査を行っています。多くの国で渡航時の陰性証明として認められている検査方法です。
                最短で当日中に結果をお知らせします。</div>
            </div>
          </div>
          
          <div class="content1">
            <div class="m_side-text">
              <div class="title1">新型コロナウイルス検査について</div>
            </div>
          </div>

          <div class="content1">
            <div class="m_side-text">
              <div class="title1">・当院ではPCR法・NEAR法による検査を行っております。</div>
            </div>
          </div>

          <div class="content1">
            <div>
              
              <div class="m_side-text">
                <div class="side-box">
                  <div class="side-line-highlight"></div>
                  <div class="title1">PCR法検査</div>
                </div>
                <div class="content_text">
                  <div class="text1" style="padding-left: 15px;">PCRは「Polymerase Chain Reaction（ポリメラーゼ連鎖反応）」の略です。この反応を用いたPCR検査はウイルスの遺伝子の特定の部分を検出する検査です。</div>

                  <div class="text1"style="padding-left: 15px;">採取した唾液にて検査いたします。新型コロナウイルスの遺伝子が含まれているかを調べ、現在の感染状況が分かります。</div>
                </div>
              </div>
              <div class="side-img">
                <img src="../../assets/departments/fever/gairai_01.png" class="fit_img">
              </div>
            </div>
          </div>

          <div>
            <div class="m_side-text">
              <div class="side-box">
                <div class="side-line-highlight"></div>
                <div class="title1">NEAR法検査</div>
              </div>
              <div class="content_text">
                <div class="text1" style="padding-left: 15px;">等温核酸増幅法（NEAR法）によるPCR検査法で、検体採取後15分で結果が判明いたします。 温度を上げ下げし拡散増幅する標準的検査法であるPCR法検査よりも短時間で判定でき、精度も同等です。</div>

                <div class="text1" style="padding-left: 15px;">ウィルス量が少なくても検出することができます。鼻を綿棒で拭い採取した液（鼻腔拭い液）から検査します。</div>
              </div>
            </div>
            <div class="side-img">
                <img src="../../assets/departments/fever/gairai_02.png" class="fit_img">
              </div>
          </div>





          <div class="content1">
            <div class="m_side-text">
              <div class="title1">・費用</div>
            </div>
          </div>
          <div class="side-box">
            <div class="m_side-text">
              <div class="content1">
                <div class="text1">PCR等検査（NEAR法）</div>
                <div class="text1">抗原定性検査</div>
                <div class="text1">証明書発行</div>
              </div>
            </div>
            <div class="m_side-text">
              <div class="content1">
                <div class="text1">９,０００円（税込）</div>
                <div class="text1">３,５００円（税込）</div>
                <div class="text1">＋１,０００円（税込）</div>
              </div>
            </div>
          </div>

          <div class="content1">
            <div class="seperate_line"></div>
          </div>

          <div class="content1">
            <div class="m_side-text">
              <div class="title1">ご予約について</div>
              <div>
                <div class="text1">発熱外来もＰＣＲ検査も完全予約制となります。</div>
                <div class="text1">ご予約はお電話にて受け付けております。</div>
                <div class="side-box" onclick="window.location.href='tel:0362606008'">
                  <div class="side-image" style="margin-top: 20px">
                    <img src="../../assets/icon/icon_tel_hover_28x28@2x.png" style="height:25px;width:25px">
                  </div>
                  <div class="text1" style="margin-top: 20px;" >
                    03-6260-6008
                  </div>
                </div>
                </div>
              <div class="table_m">
                <table>
                  <tr>
                    <td>診療時間</td>
                    <td>月</td>
                    <td>火</td>
                    <td>水</td>
                    <td>木</td>
                    <td>金</td>
                    <td>土</td>
                    <td>日</td>
                  </tr>
                  <tr>
                    <td>10:00 ～ 13:30</td>
                    <td>○</td>
                    <td>○</td>
                    <td>休</td>
                    <td>○</td>
                    <td>○</td>
                    <td>○</td>
                    <td>休</td>
                  </tr>
                  <tr>
                    <td>14:30 ～ 19:00</td>
                    <td>○</td>
                    <td>○</td>
                    <td>休</td>
                    <td>○</td>
                    <td>○</td>
                    <td>○</td>
                    <td>休</td>
                  </tr>
                </table>
              </div>

              <div>
                <div class="text1">※ 受付は３０分前までとなります。</div>
                <div class="text1">休診日：水曜・日曜・祝日・年末年始</div>
              </div>
            </div>
          </div>
        </div>
        </div>
    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
//import { classof } from "core-js/core/object";
export default {
  name: "Fever",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader}
}
</script>

<style scoped>
/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
      margin-top: 40px;
    }

    .content_text{
        margin-top: 18px;
    }
.title1 {
      color: #e5d05e;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .text1 {
      color: #535353;
      line-height: 1.5;
      margin-bottom: 15px;
    }
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

.seperate_line{
      height:5px;
      border-top-color: #e5d05e;
      border-top-width: 2px;
      border-top-style: solid;
      margin: 0 auto;
      width: 100%;
    }
    

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #e5d05e;
  border-bottom: 2px solid #e5d05e;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #f2e289;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}

/*診療時間*/
.table table {
  border-collapse: collapse;
  color: #535353;
  border-spacing: 0;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
}
.table table tr:first-child {
  background-color: #f0f0f0;
}
.table table td {
  border: 1px solid #cccccc;
  text-align: center;
  padding: 16px;
}

.table_m table {
  border-collapse: collapse;
  color: #535353;
  border-spacing: 0;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.table_m table tr:first-child {
  background-color: #f0f0f0;
}
.table_m table td {
  border: 1px solid #cccccc;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.title {
      font-size: 30px;
      letter-spacing: 5px;
      padding-top: 20px;
      padding-left: 20px;
    }

    .separator {
      width: 100%;
      margin: 0 auto;
      border-top: 4px solid;
      border-bottom: 4px solid;
    }

    .side-img{
    height: auto;
    width:auto;
    vertical-align:middle;
    margin-left: 30px;
    margin-right: 30px;
    }
  
    .side-text{
    height: fit-content;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: auto;
    }

    .m_side-text{
    height: fit-content;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    margin-bottom: auto;
    }
    
    
    .side-box{
      display: inline-flex;
      justify-content: space-between;
      align-items:center;
      vertical-align: top;
      height: auto;
    }
  
    .side-box-img{
      display: inline-flex;
      justify-content: space-between;
      align-items:top;
      vertical-align: top;
      height: auto;
    }
  
    .fit_img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

.side-line-highlight{
  background-color: #e5d05e;
  width:6px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
}



</style>
